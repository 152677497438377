@font-face {
  font-family: "Passion One";
  src: local('Passion One');
  src: url('/assets/fonts/PassionOne-Regular.ttf');
  font-weight: bold; }

body, html {
  background-color: #4d607b;
  color: white;
  font-family: sans-serif;
  margin: 0;
  padding: 0;

  /* Force footer to the bottom */
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

@mixin button {
  background-color: #4a515b;
  color: white;
  border-radius: 1000px;
  padding: .7em 2em;
  text-decoration: none;
  box-shadow: 2px 2px 1px 0 #00000078; }

@mixin yellow-button {
  @include button;
  background-color: #f0bd38;
  color: black; }

@mixin flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center; }

@media screen and (min-width: 700px) {
  .spacer {
    flex-grow: 1; } }
@media screen and (max-width: 699px) {
  header #download-button {
    display: none; } }

header, footer {
  background-color: #2b3441; }

header {
  @include flex;
  padding: 0 1em;
  img {
    max-width: 90%;
    max-height: 5em;
    padding: 1em; }
  #download-button {
    @include yellow-button;
    margin: 1em 0;
    font-size: 1.1em;
    &.display-none {
      display: none; } } }

main {
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1000px;

  /* Force footer to the bottom */
  flex-grow: 1; }

a {
  color: #f0bd38;
  &:visited {
    color: #d0a430; }

  &.button {
    @include button; }
  &.yellow-button {
    @include yellow-button; } }

h1, h2 {
  font-family: 'Passion One', sans-serif; }

h1:not(.main-title):not(.page-title) {
  margin-top: 1.5em; }

h1.main-title {
  font-size: 3em; }

h1.page-title {
  font-size: 3em;
  margin-bottom: 0; }
h2.date-header {
  margin-top: .3em;
  opacity: .7; }

h2 {
  margin-bottom: .4em; }
p {
  margin: 0; }
img {
  max-width: 100%; }

.features {
  @include flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 2em;
  margin-bottom: 2em;
  &>* {
    max-width: 25em; } }

#screenshots-thumbnails {
  display: flex;
  justify-content: center;
  max-width: 100%;
  button {
    img {
      width: 150px; }
    max-width: 25%;

    border: 3px solid transparent;
    background: none;
    padding: 0;
    &[aria-selected=true] {
      border: 3px solid #f0bd38;
      border-radius: 3px; } } }

#dl-list {
  @include flex;
  list-style: none;
  padding: 0;
  justify-content: flex-start;

  li a {
    @include button;
    margin: .5em;
    color: white; } }

.changelog-list {
  list-style: none;
  padding: 0;
  margin-left: 1em;
  li {
    margin: 1em; }
  a {
    color: white;
    text-decoration: none;
    line-height: 1.2;
    h3 {
      display: inline;
      margin: 0;
      .changelog-date {
        margin-left: 0.5em;
        color: rgba(255, 255, 255, 75%); } } } }

footer {
  @include flex;
  margin-top: 3em;

  .footer-copyright {
    padding: 1em; }

  .footer-links {
    padding-right: .7em;
    a {
      text-decoration: none; }
    img {
      height: 3em;
      vertical-align: middle;

      &.mastodon {
        height: 2em; } } } }
